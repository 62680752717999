<template>
  <!-- <div  v-if="$route.name == 'Home'">
    <router-view class="router-view" />
  </div > -->
  <div>
    <template v-if="isNavBarVisible">
      <app-featured-videos></app-featured-videos>
      <b-container fluid="xl" class="no-padding">
        <b-row class="no-gutters">
          <b-col md="3" class="left-sidebar">
            <div>
              <app-profile-card v-if="isLoggedIn" />
              <div class="sticky-ads">
                <iframe id='a7e880fb1' name='a7e880fb1' src='https://ads.clouthub.com/www/delivery/afr.php?zoneid=40&amp;cb=INSERT_RANDOM_NUMBER_HERE' frameborder='0' scrolling='no' width='250' height='250' allow='autoplay'><a href='https://ads.clouthub.com/www/delivery/ck.php?n=a543b6f2&amp;cb=INSERT_RANDOM_NUMBER_HERE' target='_blank'><img src='https://ads.clouthub.com/www/delivery/avw.php?zoneid=40&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=a543b6f2' border='0' alt='' /></a></iframe>
                <iframe id='a7e880fb1' name='a7e880fb1' src='https://ads.clouthub.com/www/delivery/afr.php?zoneid=52&amp;cb=INSERT_RANDOM_NUMBER_HERE' frameborder='0' scrolling='no' width='250' height='250' allow='autoplay'><a href='https://ads.clouthub.com/www/delivery/ck.php?n=a543b6f2&amp;cb=INSERT_RANDOM_NUMBER_HERE' target='_blank'><img src='https://ads.clouthub.com/www/delivery/avw.php?zoneid=52&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=a543b6f2' border='0' alt='' /></a></iframe>
             </div>
            </div>
          </b-col>
          <b-col md="6" class="main-content">

            <router-view class="router-view" />
          </b-col>
          <b-col md="3" class="right-sidebar">
            <app-follow-recommendations-card v-if="isLoggedIn" />
            <app-site-links-card />
            <app-ad-slot
              zoneName="ZNLC6"
              invocationCode="37"
              width="300"
              height="250"
            />
            <app-ad-slot
              zoneName="ZC7"
              invocationCode="36"
              width="300"
              height="250"
            />
            <app-ad-slot
              zoneName="ZC8"
              invocationCode="38"
              width="300"
              height="250"
            />
          </b-col>
        </b-row>
      </b-container>
      <div id="popupp" style="text-align:center;visibility:hidden" class=center >
        <span onclick="javascript:document.getElementById('popupp').style.visibility='hidden';return false" style="color:red;text-align:center;width:100%">CLOSE</span> 
        <div data-v-27147994="" style="height: 100%;">  
          <iframe data-v-27147994="" data-v-b338dd00="" id="banner-iframe" name="a5b7036a" frameborder="0" scrolling="no" allow="autoplay" src="https://ads.clouthub.com/www/delivery/afr.php?zoneid=67&amp;cb=INSERT_RANDOM_NUMBER_HERE" style="width: 100%; height: 100%;"></iframe>
        </div>
      </div>
    </template>
    <template v-else>
      <b-container fluid="xl" class="no-padding">
        <b-row class="no-gutters">
          <b-col md="12" class="main-content-full">
            <router-view class="router-view" />
          </b-col>
        </b-row>
      </b-container>
    </template>
  </div>
</template>

<script>
  
import $auth from '@/services/auth';
import AppProfileCard from '@/components/sidebar/ProfileCard.vue';
import AppDownloadCard from '@/components/sidebar/AppDownloadCard.vue';
import AppFollowRecommendationsCard from '@/components/sidebar/FollowRecommendationsCard.vue';
import AppSiteLinksCard from '@/components/sidebar/SiteLinks.vue';
import AppAdSlot from '@/components/sidebar/AdSlot.vue';
import AppFeaturedVideos from '@/components/general/FeaturedVideos.vue';

export default {
  components: {
    AppProfileCard,
    AppDownloadCard,
    AppFollowRecommendationsCard,
    AppSiteLinksCard,
    AppAdSlot,
    AppFeaturedVideos,
  },
  data() {
    const query = '';
    return {
      isLoggedIn: false,
      isNavBarVisible: true,
      query,
    };
  },
  created() {
    this.updateLoginStatus();
  },
  async mounted() {
    const routeName = this.$route.name;
    if (routeName === 'PostEmbedDetail') {
      this.isNavBarVisible = false;
    }
  },
  methods: {
    async updateLoginStatus() {
      this.isLoggedIn = await $auth.isLoggedInAsUser();
    },
    selectSearch() {
      this.$refs.input.focus();
    },
    search() {
      this.$router.push({
        name: 'UniversalSearch',
        query: {
          q: this.query,
        },
      });
    },
  },
};
</script>
<style scoped>
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  height: 480px;
  width: 1024px;
  background-color: black;
  color: red;
}
.no-padding {
  padding: 0px;
}
.ch-main-container {
  display: flex;
}

.search-bar {
  flex: 1;
  margin: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: solid 1px var(--cs-gray-02);
  border-radius: 4px;
  padding-left: 10px;
  overflow: hidden;
}

.search-bar i {
  cursor: pointer;
}
.search-bar input {
  width: 100%;
  border: none;
  height: 38px;
  padding: 10px;
  outline-width: 0;
}
.ad {
  width: 310px;
  margin-top: 20px;
}
@media (min-width: 1200px) {
  .right-sidebar {
    width: 310px !important;
    box-sizing: content-box;
  }
}
.sticky-ads {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
}
::v-deep .VueCarousel-navigation button {
    width: 40px;
    height: 175px;
    background-color: #000;
    color: #fff;
    border-radius: 6px;
}

::v-deep .VueCarousel {
  margin: 0 20px 20px;
  min-height: 138px;
}
</style>
