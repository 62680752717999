<template>
  <b-container>
    <carousel 
      :navigationEnabled="true" 
      :paginationEnabled="false" 
      :autoplay="false" 
      :autoplayTimeout="5000" 
      :speed="2000" 
      :loop="false" 
      :perPageCustom="[[1024, 3], [1200, 4], [1400, 5]]"
    >
      <slide v-for="item in featuredPosts" :key="item.id">
        <div class="px-2">
          <carousel-item :post="item" :isFeaturedPost="true"></carousel-item>
        </div>
      </slide>
    </carousel>
  </b-container>

</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
import CarouselItem from '@/components/video/CarouselItem.vue';
import FeaturedPostsDetails from '@/gql/posts/FeaturedPostsDetails.gql';

export default {
  components: {
    Carousel,
    Slide,
    CarouselItem
  },
  data() {
    return {
      featuredPosts: []
    };
  },
  async mounted() {
    const { data } = await this.$apollo.query({
      query: FeaturedPostsDetails,
      variables: {
        limit: 5
      }
    });
    this.featuredPosts = data.featuredPosts;
  }
};
</script>